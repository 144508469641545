'use client'

import React, { useState, useCallback, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { MapPin, Phone, Mail, Facebook, Instagram, Menu, X } from 'lucide-react';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from 'react-i18next';
import PDFDownloadButton from './PDFDownloadButton.jsx';


const TikTokIcon = React.memo(({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
));

const Button = ({ variant, size, onClick, children }) => (
    <button
        className={`px-3 py-1 rounded-md transition-colors duration-300 ${
            variant === 'default' ? 'bg-orange-500 text-white hover:bg-orange-600' : 'bg-white text-orange-500 border border-orange-500 hover:bg-orange-50'
        } ${size === 'sm' ? 'text-sm' : 'text-base'}`}
        onClick={onClick}
    >
        {children}
    </button>
);

export default function ApuntatPage() {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        apellidos: '',
        dni: '',
        telefono: '',
        domicilio: '',
        fechaNacimiento: '',
        loteria: 'No',
        numPapeletas: '',
        datosBancarios: '',
        consentimientoImagen: 'No'
    });
    const [errors, setErrors] = useState({});
    const pdfContentRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const navItems = [
        { name: t('inicio'), path: "/" },
        { name: t('calendario'), path: "/calendari" },
        { name: t('historia'), path: "/historia" },
        { name: t('presidentes'), path: "/presidents" },
        { name: t('falleras_mayores'), path: "/falleresMajors" },
        { name: t('fallas'), path: "/falles" },
        { name: t('casal'), path: "/casal" },
        { name: t('apuntate'), path: "/apuntat" }
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        if (name === 'loteria') {
            setFormData(prevData => ({
                ...prevData,
                numPapeletas: value === 'Si' ? prevData.numPapeletas : ''
            }));
        }
    };

    const validateForm = () => {
        let tempErrors = {};
        if (!formData.nombre) tempErrors.nombre = t('nombre_obligatorio');
        if (!formData.apellidos) tempErrors.apellidos = t('apellidos_obligatorios');
        if (!formData.dni.match(/^[0-9]{8}[A-Za-z]$/)) tempErrors.dni = t('dni_invalido');
        if (!formData.telefono.match(/^[0-9]{9}$/)) tempErrors.telefono = t('telefono_invalido');
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const today = new Date();
            const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
            const [year, month, day] = formData.fechaNacimiento.split('-');
            const formattedFechaNacimiento = `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;

            const message = `
                \u{1F680} ${t('nueva_alta')} \u{1F680} \n
                ${t('nombre')}: ${formData.nombre}\n
                ${t('apellidos')}: ${formData.apellidos}\n
                DNI: ${formData.dni}\n
                ${t('telefono')}: ${formData.telefono}\n
                ${t('domicilio')}: ${formData.domicilio}\n
                ${t('fecha_nacimiento')}: ${formattedFechaNacimiento}\n
                ${t('fecha_alta')}: ${formattedDate}\n
                ${t('loteria')}: ${formData.loteria}\n
                ${formData.loteria === 'Si' ? t('numero_papeletas') + ': ' + formData.numPapeletas + '\n' : ''}
                ${t('datos_bancarios')}: ${formData.datosBancarios}\n
                ${t('consentimiento_imagen')}: ${formData.consentimientoImagen}
            `;

            sendToTelegram(message);
        }
    };

    function sendToTelegram(message) {
        const botToken = '7135179535:AAFM-C7an7llGLDufycdZLOzz3eV9tNylp0';
        const chatId = '428411053';
        const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
        const data = {
            chat_id: chatId,
            text: message
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                if (data.ok) {
                    alert(t('formulario_enviado_exito'));
                    setFormData({
                        nombre: '',
                        apellidos: '',
                        dni: '',
                        telefono: '',
                        domicilio: '',
                        fechaNacimiento: '',
                        loteria: 'No',
                        numPapeletas: '',
                        datosBancarios: '',
                        consentimientoImagen: 'No'
                    });
                } else {
                    alert(t('error_enviar_formulario'));
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert(t('error_enviar_formulario'));
            });
    }

    const handleDownload = useCallback(() => {
        if (pdfContentRef.current) {
            const scale = 2;
            html2canvas(pdfContentRef.current, {
                logging: true,
                scale: scale,
                useCORS: true,
                allowTaint: true,
                backgroundColor: null
            }).then(canvas => {
                const imgWidth = 210;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const imgData = canvas.toDataURL("image/jpeg", 1.0);
                const pdf = new jsPDF("p", "mm", "a4");
                pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, undefined, 'FAST');
                pdf.save("FICHA_ALTA_CENSO_ADULTOS.pdf");
            }).catch(error => {
                console.error(t('error_generar_pdf'), error);
                alert(t('problema_generar_pdf'));
            });
        } else {
            console.error(t('elemento_no_encontrado'));
            alert(t('no_se_pudo_generar_pdf'));
        }
    }, [t]);

    const handleClick = useCallback((e, path) => {
        e.preventDefault();
        const shouldScroll = path === '/apuntat' || path === '/' || path === '/casal';

        if (path !== location.pathname) {
            navigate(path);
        }

        if (shouldScroll) {
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 100);
        }

        setIsMenuOpen(false);
    }, [navigate, location.pathname]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
            <header className="bg-white shadow-lg sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={(e) => handleClick(e, '/')}
                    >
                        <img src="/img/escudo-compress.webp" alt={('escudo_alt')} className="h-20 w-20 transition-transform duration-300 hover:scale-110" />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {navItems.map((item) => (
                            <Link
                                key={item.name}
                                to={item.path}
                                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                onClick={(e) => handleClick(e, item.path)}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </nav>

                    <div className="hidden md:flex items-center space-x-4">
                        <div className="flex space-x-2">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                CAS
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                VAL
                            </Button>
                        </div>
                        <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Facebook size={24} />
                        </a>
                        <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Instagram size={24} />
                        </a>
                        <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <TikTokIcon size={24} />
                        </a>
                    </div>

                    <button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label={t('toggle_menu')}
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden bg-white py-4 px-4 shadow-inner">
                        <nav className="flex flex-col space-y-4">
                            {navItems.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.path}
                                    className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                    onClick={(e) => handleClick(e, item.path)}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                        <hr className="my-4 border-gray-200" />
                        <div className="flex justify-center space-x-4 mt-4">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                ES
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                CA
                            </Button>
                        </div>
                        <div className="flex justify-center space-x-4 mt-4">
                            <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Facebook size={24} />
                            </a>
                            <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Instagram size={24} />
                            </a>
                            <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <TikTokIcon size={24} />
                            </a>
                        </div>
                    </div>
                )}
            </header>
            <main className="container mx-auto px-4 py-8">
                <h1 className="text-4xl font-bold text-center mb-8">{t('formulario_inscripcion')}</h1>
                <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md">
                    <div className="mb-4">
                        <label htmlFor="nombre" className="block text-gray-700 font-bold mb-2">{t('nombre')}:</label>
                        <input
                            type="text"
                            id="nombre"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleInputChange}
                            required
                            aria-required="true"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                        {errors.nombre && <p className="text-red-500 text-sm mt-1">{errors.nombre}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="apellidos" className="block text-gray-700 font-bold mb-2">{t('apellidos')}:</label>
                        <input
                            type="text"
                            id="apellidos"
                            name="apellidos"
                            value={formData.apellidos}
                            onChange={handleInputChange}
                            required
                            aria-required="true"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                        {errors.apellidos && <p className="text-red-500 text-sm mt-1">{errors.apellidos}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="dni" className="block text-gray-700 font-bold mb-2">DNI:</label>
                        <input
                            type="text"
                            id="dni"
                            name="dni"
                            value={formData.dni}
                            onChange={handleInputChange}
                            required
                            aria-required="true"
                            pattern="[0-9]{8}[A-Za-z]"
                            title={t('dni_valido')}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                        {errors.dni && <p className="text-red-500 text-sm mt-1">{errors.dni}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="telefono" className="block text-gray-700 font-bold mb-2">{t('telefono')}:</label>
                        <input
                            type="tel"
                            id="telefono"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleInputChange}
                            required
                            aria-required="true"
                            pattern="[0-9]{9}"
                            title={t('telefono_valido')}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                        {errors.telefono && <p className="text-red-500 text-sm mt-1">{errors.telefono}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="domicilio" className="block text-gray-700 font-bold mb-2">{t('domicilio')}:</label>
                        <input
                            type="text"
                            id="domicilio"
                            name="domicilio"
                            value={formData.domicilio}
                            onChange={handleInputChange}
                            required
                            aria-required="true"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="fechaNacimiento" className="block text-gray-700 font-bold mb-2">{t('fecha_nacimiento')}:</label>
                        <input
                            type="date"
                            id="fechaNacimiento"
                            name="fechaNacimiento"
                            value={formData.fechaNacimiento}
                            onChange={handleInputChange}
                            required
                            aria-required="true"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="loteria" className="block text-gray-700 font-bold mb-2">{t('loteria')}:</label>
                        <select
                            id="loteria"
                            name="loteria"
                            value={formData.loteria}
                            onChange={handleInputChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        >
                            <option value="No">{t('no')}</option>
                            <option value="Si">{t('si')}</option>
                        </select>
                    </div>
                    {formData.loteria === 'Si' && (
                        <div className="mb-4">
                            <label htmlFor="numPapeletas" className="block text-gray-700 font-bold mb-2">{t('numero_papeletas')}:</label>
                            <input
                                type="number"
                                id="numPapeletas"
                                name="numPapeletas"
                                value={formData.numPapeletas}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                            />
                        </div>
                    )}
                    <div className="mb-4">
                        <label htmlFor="datosBancarios" className="block text-gray-700 font-bold mb-2">{t('datos_bancarios')}:</label>
                        <input
                            type="text"
                            id="datosBancarios"
                            name="datosBancarios"
                            value={formData.datosBancarios}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="consentimientoImagen" className="block text-gray-700 font-bold mb-2">{t('consentimiento_imagen')}:</label>
                        <select
                            id="consentimientoImagen"
                            name="consentimientoImagen"
                            value={formData.consentimientoImagen}
                            onChange={handleInputChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                        >
                            <option value="No">{t('no')}</option>
                            <option value="Si">{t('si')}</option>
                        </select>
                    </div>
                    <button type="submit" className="w-full bg-orange-500 text-white font-bold py-2 px-4 rounded-md hover:bg-orange-600 transition-colors duration-300">
                        {t('enviar')}
                    </button>
                </form>

                <h1 className="text-4xl font-bold text-center mt-16 mb-8">{t('ficha_alta')}</h1>
                <p className="text-center mb-8">
                    {t('descargar_documento_instrucciones')}{' '}
                    <a href="mailto:secretariofallapadremendez@gmail.com?Subject=Fitxa%20alta%20'Ací%20el%20teu%20nom'" className="text-orange-500 hover:underline">
                        secretariofallapadremendez@gmail.com
                    </a>
                </p>

                <div className="flex justify-center mb-8">
                    <PDFDownloadButton onClick={handleDownload} text={t('descargar_pdf')} />
                </div>

                <div ref={pdfContentRef} className="max-w-2xl mx-auto">
                    <div className="relative w-full h-0 pb-[141.4%]">
                        <img
                            src="/FICHA ALTA CENSO ADULTOS_page-0001.jpg"
                            alt={('ficha_alta_alt')}
                            className="absolute top-0 left-0 w-full h-full object-contain rounded-lg shadow-lg"
                        />
                    </div>
                </div>
            </main>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img src="/img/escudo-compress.webp" alt={('escudo_alt')} className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
                            <p className="text-sm">Falla Padre Méndez © {new Date().getFullYear()}</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('contacto')}</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('siguenos')}</h3>
                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}