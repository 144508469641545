'use client'

import React, { useState, useRef, useEffect } from 'react';
import { Menu, X, MapPin, Phone, Mail, Facebook, Instagram, ChevronDown, ChevronUp } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const TikTokIcon = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
);

const Button = ({ variant, size, onClick, children }) => (
    <button
        className={`px-3 py-1 rounded-md transition-colors duration-300 ${variant === 'default' ? 'bg-orange-500 text-white hover:bg-orange-600' : 'bg-white text-orange-500 border border-orange-500 hover:bg-orange-50'
            } ${size === 'sm' ? 'text-sm' : 'text-base'}`}
        onClick={onClick}
    >
        {children}
    </button>
);

const FalleresMajorsPage = () => {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedYear, setSelectedYear] = useState('');
    const headerRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [expandedYears, setExpandedYears] = useState(new Set());
    const yearRefs = useRef({});

    useEffect(() => {
        if (location.pathname === '/falleresMajors') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const toggleYear = (year) => {
        setExpandedYears(prevYears => {
            const newExpandedYears = new Set(prevYears);
            if (newExpandedYears.has(year)) {
                newExpandedYears.delete(year);
            } else {
                newExpandedYears.add(year);
                setTimeout(() => {
                    if (yearRefs.current[year]) {
                        const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
                        const yOffset = -headerHeight - 20; // 20px de margen adicional
                        const element = yearRefs.current[year];
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                }, 100); // Pequeño retraso para asegurar que el contenido se ha expandido
            }
            return newExpandedYears;
        });
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleClick = (e, path) => {
        e.preventDefault();
        if (path === '/falleresMajors') {
            scrollToTop();
        } else {
            navigate(path);
        }
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const falleresMajors = [
        { year: "2024", adult: "Maribel García Visiedo", adultImage: "/img/2024FM-compress.webp", childImage: "/img/2024FMI-compress.webp" },
        { year: "2023", adult: "Miriam Martínez Torrijos", child: "Mª Cruz Perís Villa", adultImage: "/img/2023FM-compress.webp", childImage: "/img/2023FMI-compress.webp" },
        { year: "2022", adult: "Noelia Herrero Fargallo", child: "Andrea Marchante Herrero", adultImage: "/img/2022FM-compress.webp", childImage: "/img/2022FMI-compress.webp" },
        { year: "2020-2021", adult: "María Aleixandre Almarcha", child: "Andrea Marchante Herrero", adultImage: "/img/2020FM-compress.webp", childImage: "/img/2020FMI-compress.webp" },
        { year: "2019", adult: "Tania García Visiedo", child: "Olivia Momparler Saez", adultImage: "/img/2019FM-compress.webp", childImage: "/img/2019FMI-compress.webp" },
        { year: "2018", adult: "Esther Peris Roig", child: "Ana Martínez Navarro", adultImage: "/img/2018FM-compress.webp", childImage: "/img/2018FMI-compress.webp" },
        { year: "2017", adult: "Paula Miquel Carrión", child: "Cayetana Caballero Villena", adultImage: "/img/2017FM-compress.webp", childImage: "/img/2017FMI-compress.webp" },
        { year: "2016", adult: "Loly Tormos Clemente", child: "Ester Berlanga Royo", adultImage: "/img/2016FM-compress.webp", childImage: "/img/2016FMI-compress.webp" },
        { year: "2015", adult: "Mónica Arboleda Hernández", child: "Irene de Amo Merchante", adultImage: "/img/2015FM-compress.webp", childImage: "/img/2015FMI-compress.webp" },
        { year: "2014", adult: "Thais Escrivá Muñoz", child: "Paula Jara Gallent", adultImage: "/img/2014FM-compress.webp", childImage: "/img/2014FMI-compress.webp" },
        { year: "2013", adult: "Mireia Noguera Andreu", child: "Mireia Galán Noguera", adultImage: "/img/2013FM-compress.webp", childImage: "/img/2013FMI-compress.webp" },
        { year: "2012", adult: "Eulalia Martí Rofriguez", child: "Carmen León Fides", adultImage: "/img/2012FM-compress.webp", childImage: "/img/2012FMI-compress.webp" },
        { year: "2011", adult: "Pilar Carratalá Pascual", child: "Claudia Sanjuán Carratalá", adultImage: "/img/2011FM-compress.webp", childImage: "/img/2011FMI-compress.webp" },
        { year: "2010", adult: "Mª Cruz Cobo Cuadros", child: "Lucía Soler Melero", adultImage: "/img/2010FM-compress.webp", childImage: "/img/2010FMI-compress.webp" },
        { year: "2009", adult: "Encarnación Romero García", adultImage: "/img/2009FM-compress.webp", childImage: "/img/2009FMI-compress.webp" },
        { year: "2008", adult: "Eva María Cebrián Bascuñana", child: "Neus Domingo Ramos", adultImage: "/img/2008FM-compress.webp", childImage: "/img/2008FMI-compress.webp" },
        { year: "2007", adult: "Evelyn Oltra Veliz", child: "Paula Ramón Tordera", adultImage: "/img/2007FM-compress.webp", childImage: "/img/2007FMI-compress.webp" },
        { year: "2006", adult: "Patricia Saéz Orea", child: "Noelia Silla de Dios", adultImage: "/img/2006FM-compress.webp", childImage: "/img/2006FMI-compress.webp" },
        { year: "2005", adult: "Mónica Marchante Castillo", child: "Sofía Berenguer Sanchis", adultImage: "/img/2005FM-compress.webp", childImage: "/img/2005FMI-compress.webp" },
        { year: "2004", adult: "Elena Sanz Ramos", adultImage: "/img/2004FM-compress.webp", childImage: "/img/2004FMI-compress.webp" },
        { year: "2003", adult: "Mireia Ramón Tordera", child: "Sara Cervera Gómez", adultImage: "/img/2003FM-compress.webp", childImage: "/img/2003FMI-compress.webp" },
        { year: "2002", adult: "Verónica Esteve Puchalt", child: "Alicia Gallego Martínez", adultImage: "/img/2002FM-compress.webp", childImage: "/img/2002FMI-compress.webp" },
        { year: "2001", adult: "Sandra Planells Gómez-Lobo", child: "Marta Moreno Cáceres", adultImage: "/img/2001FM-compress.webp", childImage: "/img/2001FMI-compress.webp" },
        { year: "2000", adult: "Gloria Company Viana", child: "Vanessa Ruiz Cervera", adultImage: "/img/2000FM-compress.webp", childImage: "/img/2000FMI-compress.webp" },
        { year: "1999", adult: "Chelo Pedrero Ibañez", child: "Anabel Pérez Alarcón", adultImage: "/img/1999FM-compress.webp", childImage: "/img/1999FMI-compress.webp" },
        { year: "1998", adult: "Mª Ángeles Noguera Andreu", child: "Mª Amparo Pedrero Ibañez", adultImage: "/img/1998FM-compress.webp", childImage: "/img/1998FMI-compress.webp" },
        { year: "1997", adult: "Reyes Jorge Palmero", child: "Mireia Noguera Andreu", adultImage: "/img/1997FM-compress.webp", childImage: "/img/1997FMI-compress.webp" },
        { year: "1996", adult: "Laura Tolpsa Cervera", child: "Elena Sanz Ramos", adultImage: "/img/1996FM-compress.webp", childImage: "/img/1996FMI-compress.webp" },
        { year: "1995", adult: "Pilar Carratalá Pascual", child: "Verónica Esteve Puchalt", adultImage: "/img/1995FM-compress.webp", childImage: "/img/1995FMI-compress.webp" },
        { year: "1994", adult: "Fina Carratalá Pascual", child: "Sandra Planells Gómez-Lobo", adultImage: "/img/1994FM-compress.webp", childImage: "/img/1994FMI-compress.webp" },
        { year: "1993", adult: "Glòria Company Viana", child: "Mireia Ramón Tordera", adultImage: "/img/1993FM-compress.webp", childImage: "/img/1993FMI-compress.webp" },
        { year: "1992", adult: "Mercedes Gómez-Lobo García", child: "María Panadero Grasent", adultImage: "/img/1992FM-compress.webp", childImage: "/img/1992FMI-compress.webp" },
        { year: "1991", adult: "Manoli Giménez Sevilla", child: "María Panadero Grasent", adultImage: "/img/1991FM-compress.webp", childImage: "/img/1991FMI-compress.webp" },
        { year: "1990", adult: "Mercedes Gómez-Lobo García", child: "Yolanda Ros Lis", adultImage: "/img/1990FM-compress.webp", childImage: "/img/1990FMI-compress.webp" },
        { year: "1989", adult: "Josefina Carratalá Pascual", child: "Mª Ángeles Noguera Andreu", adultImage: "/img/1989FM-compress.webp", childImage: "/img/1989FMI-compress.webp" },
        { year: "1988", adult: "Raquel Tolosa Cervera", child: "Laura Tolosa Cervera", adultImage: "/img/1988FM-compress.webp", childImage: "/img/1988FMI-compress.webp" },
        { year: "1987", adult: "Mª José Martínez Sancho", child: "Sonia Medina Barba", adultImage: "/img/1987FM-compress.webp", childImage: "/img/1987FMI-compress.webp" },
        { year: "1986", adult: "Pilar Carratalá Pascual", child: "Reyes Jorge Palmero", adultImage: "/img/1986FM-compress.webp", childImage: "/img/1986FMI-compress.webp" },
        { year: "1985", adult: "Eva Mª Gassent Vilata", child: "Mónica Calas Clar", adultImage: "/img/1985FM-compress.webp", childImage: "/img/1985FMI-compress.webp" },
        { year: "1984", adult: "Pilar García Navarro", child: "Lidia García Tárrega", adultImage: "/img/1984FM-compress.webp", childImage: "/img/1984FMI-compress.webp" },
        { year: "1983", adult: "Asunción García Cervera", child: "Mª José Soriano Gassent", adultImage: "/img/1983FM-compress.webp", childImage: "/img/1983FMI-compress.webp" },
        { year: "1982", adult: "Consol Cervera Cervera", child: "", adultImage: "/img/1982FM-compress.webp", childImage: "/img/1982FMI-compress.webp" },
        { year: "1981", adult: "Dolores Cuadros González", child: "Ana María Copete Jover", adultImage: "/img/1981FM-compress.webp", childImage: "/img/1981FMI-compress.webp" },
        { year: "1980", adult: "Desamparados Fajardo Pons", child: "Mª José Perpiña Arroyo", adultImage: "/img/1980FM-compress.webp", childImage: "/img/1980FMI-compress.webp" },
        { year: "1979", adult: "Josefina Mora Cervera", child: "Gloria Company Viana", adultImage: "/img/1979FM-compress.webp", childImage: "/img/1979FMI-compress.webp" },
        { year: "1978", adult: "Ángeles Tordera Vilanova", child: "Carmen Mora Fernández", adultImage: "/img/1978FM-compress.webp", childImage: "/img/1978FMI-compress.webp" },
        { year: "1977", adult: "Mª Carmen Martínez Sancho", child: "Mª Cristina Perpiña Arroyo", adultImage: "/img/1977FM-compress.webp", childImage: "/img/1977FMI-compress.webp" },
        { year: "1976", adult: "Consuelo Ibañez Martinez", child: "Isabel González Ruiz", adultImage: "/img/1976FM-compress.webp", childImage: "/img/1976FMI-compress.webp" },
        { year: "1975", adult: "Rosa Mª Inserte Iborrra", child: "Asunción García Martí", adultImage: "/img/1975FM-compress.webp", childImage: "/img/1975FMI-compress.webp" },
        { year: "1974", adult: "Elvira Ruiz de Elvira Martínez", child: "Mª Ángeles Maho Roldán", adultImage: "/img/1974FM-compress.webp", childImage: "/img/1974FMI-compress.webp" },
        { year: "1973", adult: "Conchin Bermell Montesa", child: "José Maho Roldán", adultImage: "/img/1973FM-compress.webp", childImage: "/img/1973FMI-compress.webp" }
    ];

    const navItems = [
        { name: t('inicio'), path: "/" },
        { name: t('calendario'), path: "/calendari" },
        { name: t('historia'), path: "/historia" },
        { name: t('presidentes'), path: "/presidents" },
        { name: t('falleras_mayores'), path: "/falleresMajors" },
        { name: t('fallas'), path: "/falles" },
        { name: t('casal'), path: "/casal" },
        { name: t('apuntate'), path: "/apuntat" }
    ];

    const filteredFalleres = selectedYear
        ? falleresMajors.filter(fallera => fallera.year === selectedYear)
        : falleresMajors;

    return (
        <div className="min-h-screen bg-white text-gray-900">
            <header ref={headerRef} className="bg-white shadow-lg sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={(e) => handleClick(e, '/')}
                    >
                        <img src="/img/escudo-compress.webp" alt={t('escudo_alt')} className="h-20 w-20 transition-transform duration-300 hover:scale-110" />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {navItems.map((item) => (
                            <Link
                                key={item.name}
                                to={item.path}
                                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                onClick={(e) => handleClick(e, item.path)}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </nav>
                    <div className="hidden md:flex items-center space-x-4">
                        <div className="flex space-x-2">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                CAS
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                VAL
                            </Button>
                        </div>
                        <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Facebook size={24} />
                        </a>
                        <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Instagram size={24} />
                        </a>
                        <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <TikTokIcon size={24} />
                        </a>
                    </div>
                    <button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label={t('toggle_menu')}
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden bg-white py-4 px-4 shadow-inner">
                        <nav className="flex flex-col space-y-4">
                            {navItems.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.path}
                                    className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                    onClick={(e) => handleClick(e, item.path)}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                        <hr className="my-4 border-gray-200" />
                        <div className="flex justify-center space-x-4 mt-4">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                ES
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                CA
                            </Button>
                        </div>
                        <div className="flex justify-center space-x-4 mt-4">
                            <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Facebook size={24} />
                            </a>
                            <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Instagram size={24} />
                            </a>
                            <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <TikTokIcon size={24} />
                            </a>
                        </div>
                    </div>
                )}
            </header>

            <main className="py-8 md:py-16">
                <div className="container mx-auto px-4">
                    <motion.h1
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="text-4xl md:text-5xl font-bold text-center mb-12 bg-gradient-to-r from-orange-500 to-red-600 text-transparent bg-clip-text"
                    >
                        {t('falleras_mayores')}
                    </motion.h1>

                    <div className="mb-8">
                        <label htmlFor="year-select" className="block text-sm font-medium text-gray-700 mb-2">
                            {t('filter_by_year')}:
                        </label>
                        <select
                            id="year-select"
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                        >
                            <option value="">{t('all_years')}</option>
                            {falleresMajors.map((fallera) => (
                                <option key={fallera.year} value={fallera.year}>
                                    {fallera.year}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="space-y-4">
                        {filteredFalleres.map((fallera) => (
                            <div
                                key={fallera.year}
                                ref={el => yearRefs.current[fallera.year] = el}
                                className="bg-gray-100 rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg border border-gray-200"
                            >
                                <div
                                    className="p-4 cursor-pointer bg-gradient-to-r from-orange-300 to-red-100"
                                    onClick={() => toggleYear(fallera.year)}
                                >
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <h2 className="text-2xl font-bold text-gray-800">{fallera.year}</h2>
                                            <p className="text-sm text-gray-600">
                                                {t('FM')}: {fallera.adult}
                                                {fallera.child && ` | ${t('FMI')}: ${fallera.child}`}
                                            </p>
                                        </div>
                                        {expandedYears.has(fallera.year) ? <ChevronUp className="text-gray-600" /> : <ChevronDown className="text-gray-600" />}
                                    </div>
                                </div>
                                <AnimatePresence>
                                    {expandedYears.has(fallera.year) && (
                                        <motion.div
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3 }}
                                            className="p-4"
                                        >
                                            <div className="flex flex-col md:flex-row gap-4">
                                                <div className="flex-1 space-y-2">
                                                    <img
                                                        src={fallera.adultImage}
                                                        alt={t('fm_alt', { year: fallera.year })}
                                                        className="w-full h-auto max-w-56 mx-auto object-cover rounded-lg shadow-sm transition-transform duration-300 hover:scale-105"
                                                    />
                                                    <div className="text-center">
                                                        <h3 className="text-lg font-semibold text-gray-800">{t('fallera_mayor')}</h3>
                                                        <p className="text-sm text-gray-600">{fallera.adult}</p>
                                                    </div>
                                                </div>
                                                {fallera.child && (
                                                    <div className="flex-1 space-y-2">
                                                        <img
                                                            src={fallera.childImage}
                                                            alt={t('fmi_alt', { year: fallera.year })}
                                                            className="w-full h-auto max-w-56 mx-auto object-cover rounded-lg shadow-sm transition-transform duration-300 hover:scale-105"
                                                        />
                                                        <div className="text-center">
                                                            <h3 className="text-lg font-semibold text-gray-800">{t('fallera_mayor_infantil')}</h3>
                                                            <p className="text-sm text-gray-600">{fallera.child}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img src="/img/escudo-compress.webp" alt={t('escudo_alt')} className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
                            <p className="text-sm">Falla Padre Méndez © {new Date().getFullYear()}</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('contacto')}</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('siguenos')}</h3>
                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default FalleresMajorsPage;