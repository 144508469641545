'use client'

import React, { useState, useEffect } from 'react'
import { Menu, X, MapPin, Phone, Mail, Facebook, Instagram } from 'lucide-react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'

const TikTokIcon = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
)

const Button = ({ variant, size, onClick, children }) => (
    <button
        className={`px-3 py-1 rounded-md transition-colors duration-300 ${variant === 'default' ? 'bg-orange-500 text-white hover:bg-orange-600' : 'bg-white text-orange-500 border border-orange-500 hover:bg-orange-50'
            } ${size === 'sm' ? 'text-sm' : 'text-base'}`}
        onClick={onClick}
    >
        {children}
    </button>
)

export default function FundacioPage() {
    const { t, i18n } = useTranslation()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalSrc, setModalSrc] = useState('')

    const navigate = useNavigate()
    const location = useLocation()

    const handleClick = (e, path) => {
        e.preventDefault()
        if (path === '/' || path === '/historia') {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        if (path !== location.pathname) {
            navigate(path)
        }
        setIsMenuOpen(false)
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }

    const navItems = [
        { name: t('inicio'), path: "/" },
        { name: t('calendario'), path: "/calendari" },
        { name: t('historia'), path: "/historia" },
        { name: t('presidentes'), path: "/presidents" },
        { name: t('falleras_mayores'), path: "/falleresMajors" },
        { name: t('fallas'), path: "/falles" },
        { name: t('casal'), path: "/casal" },
        { name: t('apuntate'), path: "/apuntat" }
    ]

    const openModal = (src) => {
        setModalSrc(src)
        setModalOpen(true)
    }

    const founders = [
        "Isidro", "Melquiades", "Tio Pepe", "Sebastian", "Paco 'el de la rata'",
        "Cantero", "Pepe Company", "Jose Perpiñá", "Salvador Company", "Manuel Isarte",
        "Conejeros", "Enrique Cervera", "Jose Sanz 'el obispo'", "Angel Gonzalez", "Paco Gonzalez"
    ]

    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
            <header className="bg-white shadow-lg sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={(e) => handleClick(e, '/')}
                    >
                        <img src="/img/escudo-compress.webp" alt={t('escudo_alt')} className="h-20 w-20 transition-transform duration-300 hover:scale-110" />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {navItems.map((item) => (
                            <Link
                                key={item.name}
                                to={item.path}
                                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                onClick={(e) => handleClick(e, item.path)}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </nav>

                    <div className="hidden md:flex items-center space-x-4">
                        <div className="flex space-x-2">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                CAS
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                VAL
                            </Button>
                        </div>
                        <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Facebook size={24} />
                        </a>
                        <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Instagram size={24} />
                        </a>
                        <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <TikTokIcon size={24} />
                        </a>
                    </div>

                    <button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label={t('toggle_menu')}
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden bg-white py-4 px-4 shadow-inner">
                        <nav className="flex flex-col space-y-4">
                            {navItems.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.path}
                                    className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                    onClick={(e) => handleClick(e, item.path)}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                        <hr className="my-4 border-gray-200" />
                        <div className="flex justify-center space-x-4 mt-4">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                ES
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                CA
                            </Button>
                        </div>
                        <div className="flex justify-center space-x-4 mt-4">
                            <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Facebook size={24} />
                            </a>
                            <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Instagram size={24} />
                            </a>
                            <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <TikTokIcon size={24} />
                            </a>
                        </div>
                    </div>
                )}
            </header>
            <main className="container mx-auto px-4 py-12">
                <motion.h1
                    className="text-4xl md:text-5xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-600"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    {t('fundacion')}
                </motion.h1>

                <div className="space-y-12">

                    <div className="flex justify-center">
                        <img
                            src="/img/1acta-compress.webp"
                            alt={t('fundacion_alt')}
                            className="w-full max-w-2xl h-auto object-contain cursor-pointer rounded-lg shadow-lg shadow-gray-500/50"
                        />
                    </div>
                    <div className="p-6">
                        <h2 className="text-xl font-semibold mb-4 text-center">{t('acta_fundacional')}</h2>

                    </div>


                    <motion.div
                        className="bg-white rounded-lg shadow-lg p-6"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <h2 className="text-3xl font-semibold mb-4 text-center">{t('historia')}</h2>
                        <div className="space-y-4 text-gray-600">
                            <p>{t('fundacion_p1')}</p>
                            <p>{t('fundacion_p2')}</p>
                            <p>{t('fundacion_p3')}</p>
                            <p>{t('fundacion_p4')}</p>
                            <p>{t('fundacion_p5')}</p>
                        </div>
                    </motion.div>

                    <motion.div
                        className="bg-white rounded-lg shadow-lg p-6"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <h2 className="text-3xl font-semibold mb-4 text-center">{t('fundadores')}</h2>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                            {founders.map((name, index) => (
                                <div key={index} className="bg-orange-100 p-4 rounded-lg text-center text-orange-800">
                                    {name}
                                </div>
                            ))}
                        </div>
                    </motion.div>


                    <h2 className="text-3xl font-semibold mb-4 text-center">{t('galeria')}</h2>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        {[1, 2, 3, 4].map((num) => (
                            <img
                                key={num}
                                src={`/img/fundacio${num}-compress.webp`}
                                alt={t('fundacion_img_alt', { number: num })}
                                className="w-full h-auto object-cover rounded-lg shadow-md cursor-pointer transition-transform hover:scale-105"
                                onClick={() => openModal(`/img/fundacio${num}-compress.webp`)}
                            />
                        ))}
                    </div>
                </div>
            </main>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img src="/img/escudo-compress.webp" alt="escudo de la Falla Padre Méndez" className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
                            <p className="text-sm">Falla Padre Méndez © 2024</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('contacto')}</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('siguenos')}</h3>
                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <AnimatePresence>
                {modalOpen && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                        onClick={() => setModalOpen(false)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white p-4 rounded-lg flex items-center justify-center max-w-[90vw] max-h-[90vh] overflow-auto"
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <img
                                src={modalSrc}
                                alt={t('modal_img_alt')}
                                className="max-w-full max-h-full object-contain" // Cambia a object-contain para mantener la relación de aspecto
                            />
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

        </div>
    )
}