import React, { useState, useEffect, useRef } from 'react';
import { Menu, X, MapPin, Phone, Mail, Facebook, Instagram, ChevronLeft, ChevronRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence, useMotionValue, useSpring, useTransform } from "framer-motion";
const TikTokIcon = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
);

const Button = ({ variant, size, onClick, children }) => (
    <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`px-3 py-1 rounded-md transition-colors duration-300 ${variant === 'default' ? 'bg-orange-500 text-white' : 'bg-white text-orange-500 border border-orange-500'
            } ${size === 'sm' ? 'text-sm' : 'text-base'}`}
        onClick={onClick}
    >
        {children}
    </motion.button>
);

const ACCESS_TOKEN = 'IGQWRQUFIwLU0xYkhuXzJKM3k2b1d1S0lzNkVaUjY1cVAxSlhIUzVrdEFfSjdzbkxBQVNTSVNnWDdaZAHQ2Mk1IWllMaFIxNktNVEswYlpTY2tMS3YyWWVFLV93NkJvZAlZAGSTF6QmtVNnQwbEFNTFNMZA2pKOXNNamcZD';
const USER_ID = '7786844568027872';

function InstagramFeed() {
    const { t } = useTranslation();
    const [posts, setPosts] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const feedRef = useRef(null);
    const headerHeight = 80;

    const scrollToFeed = () => {
        if (feedRef.current) {
            const headerHeight = document.querySelector('header')?.offsetHeight || 0;
            const feedTop = feedRef.current.getBoundingClientRect().top + window.pageYOffset - headerHeight;
            window.scrollTo({
                top: feedTop,
                behavior: 'smooth'
            });
        }
    };


    useEffect(() => {
        fetchInstagramPosts(startIndex);
    }, [startIndex]);

    async function fetchInstagramPosts(index) {
        try {
            const response = await fetch(
                `https://graph.instagram.com/${USER_ID}/media?fields=id,caption,media_type,media_url,permalink,timestamp,children{media_url,media_type}&access_token=${ACCESS_TOKEN}`
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPosts(data.data.slice(index, index + 3));
        } catch (error) {
            console.error('Error fetching Instagram posts:', error);
        }
    }

    const handleNext = () => {
        setStartIndex(prevIndex => prevIndex + 3);
        setTimeout(scrollToFeed, 100);
    };

    const handlePrev = () => {
        setStartIndex(prevIndex => Math.max(0, prevIndex - 3));
        setTimeout(scrollToFeed, 100);
    };


    return (
        <section
            ref={feedRef}
            className="py-16 bg-gradient-to-r from-orange-100 to-red-100"
        >
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-12">{t('ultimas_publicaciones')}</h2>
                <div className="flex flex-col items-center gap-4">
                    {posts.map((post) => (
                        <div
                            key={post.id}
                            className="bg-white rounded-lg shadow-md w-full max-w-md"
                        >
                            <div className="p-4">
                                <h3 className="font-bold text-lg">{post.caption.split('\n')[0]}</h3>
                            </div>
                            <div className="p-0">
                                {post.media_type === 'VIDEO' ? (
                                    <video src={post.media_url} controls className="w-full h-auto object-cover" />
                                ) : (
                                    <img src={post.media_url} alt={post.caption} className="w-full h-auto object-cover" />
                                )}
                            </div>
                            <div className="p-4 flex flex-col items-start">
                                <p className="text-sm text-gray-600 mb-2">{new Date(post.timestamp).toLocaleDateString()}</p>
                                <button
                                    className="text-blue-600 hover:text-blue-800"
                                    onClick={() => window.open(post.permalink, '_blank')}
                                >
                                    {t('leer_mas')}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center mt-8 space-x-4">
                    <Button
                        variant="default"
                        onClick={handlePrev}
                        disabled={startIndex === 0}
                    >
                        {t('anterior')}
                    </Button>
                    <Button
                        variant="default"
                        onClick={handleNext}
                    >
                        {t('siguiente')}

                    </Button>

                </div>
            </div>
        </section>
    );
}


export default function HomePage() {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [yearsSinceFoundation, setYearsSinceFoundation] = useState(0);
    const [showVideo, setShowVideo] = useState(false);
    const navigate = useNavigate();
    const cardRef = useRef(null);
    const mouseX = useMotionValue(0);
    const mouseY = useMotionValue(0);


    const rotateX = useTransform(mouseY, [-300, 300], ["10deg", "-10deg"]);
    const rotateY = useTransform(mouseX, [-300, 300], ["-10deg", "10deg"]);

    const shadowX = useTransform(mouseX, [-300, 300], [50, -50]);
    const shadowY = useTransform(mouseY, [-300, 300], [50, -50]);
    const shadowBlur = useTransform(
        [mouseX, mouseY],
        ([latestX, latestY]) => Math.sqrt(latestX ** 2 + latestY ** 2) / 3
    );
    const shadowOpacity = useTransform(
        [mouseX, mouseY],
        ([latestX, latestY]) => Math.min(Math.sqrt(latestX ** 2 + latestY ** 2) / 300, 0.8)
    );

    const springConfig = { damping: 20, stiffness: 300 };
    const rotateXSpring = useSpring(rotateX, springConfig);
    const rotateYSpring = useSpring(rotateY, springConfig);
    const shadowXSpring = useSpring(shadowX, springConfig);
    const shadowYSpring = useSpring(shadowY, springConfig);
    const shadowBlurSpring = useSpring(shadowBlur, springConfig);
    const shadowOpacitySpring = useSpring(shadowOpacity, springConfig);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [
        '/img/portada.webp',
        '/img/portada1.webp',
        '/img/portada2.webp',
        '/img/portada3.webp',
    ];

    const sponsors = [
        { name: "Sponsor 1", logo: "/placeholder.svg?height=100&width=200" },
        { name: "Sponsor 2", logo: "/placeholder.svg?height=100&width=200" },
        { name: "Sponsor 3", logo: "/placeholder.svg?height=100&width=200" },
        { name: "Sponsor 4", logo: "/placeholder.svg?height=100&width=200" },
        { name: "Sponsor 5", logo: "/placeholder.svg?height=100&width=200" },
        { name: "Sponsor 6", logo: "/placeholder.svg?height=100&width=200" }
    ];

    const pulsateAnimation = {
        scale: [1, 1.50, 1],
        transition: {
            duration: 1,
            repeat: Infinity,
            ease: "easeInOut"
        }
    };
    const monuments = [
        {
            title: t('monumento_mayor'),
            lema: "Mare Meua",
            artista: "Dani Barea",
            image: "/img/fmajor-compress.webp?height=400&width=300",
        },
        {
            title: t('monumento_infantil'),
            lema: "La més dolça",
            artista: "Dani Barea",
            image: "/img/finfantil-compress.webp?height=400&width=300",
        },
    ];


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [images.length]);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleLogoClick = (e) => {
        e.preventDefault();
        setShowVideo(true);
    };

    useEffect(() => {
        const targetDate = new Date('2025-03-19T00:00:00').getTime();

        const updateCountdown = () => {
            const now = new Date().getTime();
            const difference = targetDate - now;

            if (difference > 0) {
                setCountdown({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds: Math.floor((difference % (1000 * 60)) / 1000)
                });
            } else {
                setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        };

        const timer = setInterval(updateCountdown, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const foundationYear = 1972;
        setYearsSinceFoundation(currentYear - foundationYear);
    }, []);

    const handleMouseMove = (e) => {
        const rect = cardRef.current.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        mouseX.set(e.clientX - centerX);
        mouseY.set(e.clientY - centerY);
    };

    const handleMouseLeave = () => {
        mouseX.set(0);
        mouseY.set(0);
    };

    const handleClick = (e, path) => {
        e.preventDefault();
        if (path === '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            navigate(path);
        }
        setIsMenuOpen(false);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const navItems = [
        { name: t('inicio'), path: "/" },
        { name: t('calendario'), path: "/calendari" },
        { name: t('historia'), path: "/historia" },
        { name: t('presidentes'), path: "/presidents" },
        { name: t('falleras_mayores'), path: "/falleresMajors" },
        { name: t('fallas'), path: "/falles" },
        { name: t('casal'), path: "/casal" },
        { name: t('apuntate'), path: "/apuntat" },

    ];
    return (
        <div className="max-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
            <motion.header
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ type: "spring", stiffness: 100 }}
                className="bg-white shadow-lg sticky top-0 z-50"
            >
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={(e) => handleClick(e, '/')}
                    >
                        <motion.img
                            src="/img/escudo-compress.webp"
                            alt="Escudo de la Falla Padre Méndez"
                            className="h-20 w-20 cursor-pointer"
                            whileHover={{ scale: 1.1 }}
                            onClick={handleLogoClick}
                        />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {navItems.map((item) => (
                            <motion.div
                                key={item.name}
                                whileHover={{ scale: 1.1 }}
                            >
                                <Link
                                    to={item.path}
                                    className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                    onClick={(e) => handleClick(e, item.path)}
                                >
                                    {item.name}
                                </Link>
                            </motion.div>
                        ))}
                    </nav>

                    <div className="hidden md:flex items-center space-x-4">
                        <div className="flex space-x-2">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                CAS
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                VAL
                            </Button>
                        </div>
                        <motion.a
                            href="https://www.facebook.com/fallaparemendez?locale=es_ES"
                            aria-label="Facebook"
                            className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                            whileHover={{ scale: 1.2 }}
                        >
                            <Facebook size={24} />
                        </motion.a>
                        <motion.a
                            href="https://www.instagram.com/fallaparemendez/"
                            aria-label="Instagram"
                            className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                            whileHover={{ scale: 1.2 }}
                        >
                            <Instagram size={24} />
                        </motion.a>
                        <motion.a
                            href="https://www.tiktok.com/@fallaparemendez"
                            aria-label="TikTok"
                            className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                            whileHover={{ scale: 1.2 }}
                        >
                            <TikTokIcon size={24} />
                        </motion.a>
                    </div>

                    <motion.button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label="Toggle menu"
                        whileTap={{ scale: 0.95 }}
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </motion.button>
                </div>
                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            className="md:hidden bg-white py-4 px-4 shadow-inner"
                        >
                            <nav className="flex flex-col space-y-4">
                                {navItems.map((item) => (
                                    <motion.div
                                        key={item.name}
                                        whileHover={{ scale: 1.05 }}
                                    >
                                        <Link
                                            to={item.path}
                                            className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                            onClick={(e) => handleClick(e, item.path)}
                                        >
                                            {item.name}
                                        </Link>
                                    </motion.div>
                                ))}
                            </nav>
                            <hr className="my-4 border-gray-200" />
                            <div className="flex justify-center space-x-4 mt-4">
                                <Button
                                    variant={i18n.language === 'es' ? "default" : "outline"}
                                    size="sm"
                                    onClick={() => changeLanguage('es')}
                                >
                                    ES
                                </Button>
                                <Button
                                    variant={i18n.language === 'ca' ? "default" : "outline"}
                                    size="sm"
                                    onClick={() => changeLanguage('ca')}
                                >
                                    CA
                                </Button>
                            </div>
                            <div className="flex justify-center space-x-4 mt-4">
                                <motion.a
                                    href="https://www.facebook.com/fallaparemendez?locale=es_ES"
                                    aria-label="Facebook"
                                    className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                    whileHover={{ scale: 1.2 }}
                                >
                                    <Facebook size={24} />
                                </motion.a>
                                <motion.a
                                    href="https://www.instagram.com/fallaparemendez/"
                                    aria-label="Instagram"
                                    className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                    whileHover={{ scale: 1.2 }}
                                >
                                    <Instagram size={24} />
                                </motion.a>
                                <motion.a
                                    href="https://www.tiktok.com/@fallaparemendez"
                                    aria-label="TikTok"
                                    className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                    whileHover={{ scale: 1.2 }}
                                >
                                    <TikTokIcon size={24} />
                                </motion.a>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.header>

            <main>
                <motion.section
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                    className="relative h-[85vh] overflow-hidden"
                >
                    <div className="absolute inset-0">
                        {images.map((img, index) => (
                            <div
                                key={img}
                                className={`absolute inset-0 transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                                    }`}
                            >
                                <div
                                    className="w-full h-full bg-center bg-no-repeat bg-cover"
                                    style={{
                                        backgroundImage: `url(${img})`,
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="absolute inset-0 bg-black bg-opacity-20" />
                    <div className="relative container mx-auto px-4 h-full flex flex-col justify-center items-center text-center ">
                        <motion.img
                            src="/img/movil-compress.webp"
                            alt="Escudo de la Falla Padre Méndez"
                            className="h-32 w-32 md:h-32 md:w-32 lg:h-44 lg:w-44 mb-8 rounded-lg shadow-lg"
                            style={{ filter: 'drop-shadow(1px 1px 2px red) drop-shadow(0 0 1em orange) drop-shadow(0 0 0.2em orange)' }}
                            initial={{ y: -50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ type: "spring", stiffness: 100, delay: 0.5 }}
                        />

                        <motion.div
                            className="p-4 rounded-lg mb-0 max-w-2xl " // Cambiado el padding a p-4 para un fondo más estrecho
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ type: "spring", stiffness: 100, delay: 0.7 }}
                        >
                            <h1 className="text-3xl md:text-3xl lg:text-4xl font-bold text-white  p-2 rounded-lg drop-shadow-2xl" style={{ textShadow: '1px 1px 2px red, 0 0 1em orange, 0 0 0.2em orange' }}>
                                {t('nombreFalla')}
                            </h1>
                        </motion.div>

                        <motion.div
                            className=" rounded-lg mb-0 max-w-xl bg-transparent" // Cambiado el padding a p-2
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ type: "spring", stiffness: 100, delay: 0.9 }}
                        >
                            <p className="text-lg md:text-lg lg:text-xl text-white drop-shadow-lg  p-2 rounded-lg m-4" style={{ textShadow: '1px 1px 2px red, 0 0 1em orange, 0 0 0.2em orange' }}> {/* Cambiado a text-black */}
                                {t('fundada_en')}
                            </p>
                        </motion.div>

                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="bg-white text-black text-xs md:text-sm lg:text-sm font-bold py-3 px-8 rounded-full hover:bg-orange-500 hover:text-white transition-colors duration-300 shadow-md"
                            onClick={() => {
                                const section = document.getElementById("bienvenidos")
                                if (section) {
                                    section.scrollIntoView({ behavior: "smooth", block: "start" })
                                    const headerOffset = document.querySelector('header')?.offsetHeight || 0
                                    const elementPosition = section.getBoundingClientRect().top
                                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                    })
                                }
                            }}
                        >
                            {t('descubre_mas')}
                        </motion.button>
                    </div>
                </motion.section>


                <section id="bienvenidos" className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-8">{t('bienvenidos')}</h2>
                        <motion.div
                            ref={cardRef}
                            className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg shadow-orange-500 overflow-hidden bg-gradient-to-r from-orange-100 to-red-100"
                            style={{
                                perspective: '1000px',
                                transformStyle: 'preserve-3d',
                            }}
                            animate={{
                                rotateX: rotateXSpring,
                                rotateY: rotateYSpring,
                            }}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="p-8">
                                <motion.p
                                    className="text-lg leading-relaxed mb-4"
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    {t('bienvenidos_p1')}
                                </motion.p>
                                <motion.p
                                    className="text-lg leading-relaxed"
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.2 }}
                                >
                                    {t('bienvenidos_p3')}
                                </motion.p>
                            </div>
                        </motion.div>
                    </div>
                </section>

                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">{t('representantes')} <span className="text-primary">2025</span></h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                            {[
                                { cargo: t('fallera_mayor'), nombre: "Marisol Merchante Castillo", image: "/img/marisol-compress.webp?height=300&width=200" },
                                { cargo: t('presidenta'), nombre: "Pilar Carratalá Pascual", image: "/img/presidenta-compress.webp?height=300&width=200" },
                                { cargo: t('fallera_mayor_infantil'), nombre: "Carlota Sanz Merchante", image: "/img/Carlota.webp?height=300&width=200" },
                                { cargo: t('presidente_infantil'), nombre: "Francisco Javier Galán Noguera", image: "/img/Javier.webp?height=300&width=200" }
                            ].map((representante) => (
                                <div
                                    key={representante.cargo}
                                    className="bg-white rounded-lg shadow-md overflow-visible flex flex-col relative"
                                >
                                    <div className="flex-shrink-0 flex justify-center items-center overflow-visible relative" style={{ height: '300px' }}>
                                        <img
                                            src={representante.image}
                                            alt={representante.nombre}
                                            className="object-contain h-full transition-transform duration-300 ease-in-out transform hover:scale-125 hover:z-10 hover:drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" // Añadido 'hover:shadow-lg' para sombra
                                        />
                                    </div>
                                    <div className="p-4 flex flex-col justify-center h-32">
                                        <h3 className="font-bold text-lg mb-2 text-primary">{representante.cargo}</h3>
                                        <p className="text-gray-600">{representante.nombre}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/*  <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">{t('patrocinadoreslogo')}</h2>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
                            {sponsors.map((sponsor, index) => (
                                <motion.div
                                    key={sponsor.name}
                                    className="bg-gradient-to-r from-orange-100 to-red-100 rounded-lg shadow-md p-4 flex items-center justify-center"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                >
                                    <motion.img
                                        src={sponsor.logo}
                                        alt={sponsor.name}
                                        className="max-w-full max-h-20 object-contain"
                                        animate={pulsateAnimation}
                                    />
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </section> */}

                <section className="py-16 bg-gradient-to-r from-orange-100 to-red-100">
                    <div className="container mx-auto px-4 text-center">
                        <h2 className="text-3xl font-bold mb-8">{t('falles')} <span className="text-primary">2025</span></h2>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                            {Object.entries(countdown).map(([unit, value]) => (
                                <div
                                    key={unit}
                                    className="bg-white rounded-lg shadow-md p-6"
                                >
                                    <div className="text-4xl font-bold text-primary mb-2">
                                        {value}
                                    </div>
                                    <div className="text-gray-600 capitalize">{t(unit)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="py-16 ">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">{t('datos_historicos')}</h2>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                            {[
                                { title: t('anos'), number: yearsSinceFoundation },
                                { title: t('comision'), number: '+400' },
                                { title: t('fallera_mayor_torrent'), number: 1 },
                                { title: t('fallera_mayor_infantil_torrent'), number: 1 },
                                { title: t('corte_honor'), number: 5 },
                                { title: t('presidentes'), number: 15 }
                            ].map((dato) => (
                                <div
                                    key={dato.title}
                                    className="bg-gradient-to-r from-orange-100 to-red-100 rounded-lg shadow-md p-6 text-center"
                                >
                                    <div className="text-4xl font-bold text-primary mb-2">
                                        {dato.number}
                                    </div>
                                    <div className="text-gray-600">{dato.title}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">
                            {t('monumentos')} <span className="text-primary">2024!</span>
                        </h2>
                        <div className="flex justify-center flex-wrap gap-8 mb-12">
                            {monuments.map((monument) => (
                                <div
                                    key={monument.title}
                                    className="bg-white rounded-lg shadow-md overflow-visible w-auto max-w-xs mx-auto"
                                >
                                    <div className="relative p-4 bg-gradient-to-r from-orange-100 to-red-100">
                                        <img
                                            src={monument.image}
                                            alt={monument.title}
                                            className="w-full h-80 object-contain transition-transform duration-500 ease-in-out transform hover:scale-150 hover:z-10 hover:drop-shadow-[12px_21px_26px_rgba(230,97,0,0.4)]"
                                        />
                                    </div>
                                    <div className="p-6 text-center mt-4 bg-gradient-to-r from-orange-100 to-red-100">
                                        <h3 className="font-bold text-xl mb-2 text-primary">
                                            {monument.title}
                                        </h3>
                                        <p className="text-gray-600 mb-2">
                                            {t('lema')}: {monument.lema}
                                        </p>
                                        <p className="text-gray-600">
                                            {t('artista')}: {monument.artista}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="mt-12 text-center">
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                                className="inline-block"
                            >
                                <img
                                    src="/img/Torren-logotipo.svg?height=100&width=200"
                                    alt={t('ajuntamentLogo')}
                                    className="mx-auto h-20 object-contain"
                                />
                                <p className="mt-6 text-xl text-gray-600">{t('subvencion_text')}</p>
                            </motion.div>
                        </div>
                    </div>
                </section>


                <InstagramFeed />

            </main>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img
                                src="/img/escudo-compress.webp"
                                alt="escudo de la Falla Padre Méndez"
                                className="h-24 w-auto mb-4 rounded-full border-2 border-white"
                            />
                            <p className="text-sm">Falla Padre Méndez © 2024</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('contacto')}</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('siguenos')}</h3>
                            <div className="flex space-x-4">
                                <a
                                    href="https://www.facebook.com/fallaparemendez?locale=es_ES"
                                    aria-label="Facebook"
                                    className="hover:text-orange-200 transition-colors duration-300"
                                >
                                    <Facebook size={24} />
                                </a>
                                <a
                                    href="https://www.instagram.com/fallaparemendez/"
                                    aria-label="Instagram"
                                    className="hover:text-orange-200 transition-colors duration-300"
                                >
                                    <Instagram size={24} />
                                </a>
                                <a
                                    href="https://www.tiktok.com/@fallaparemendez"
                                    aria-label="TikTok"
                                    className="hover:text-orange-200 transition-colors duration-300"
                                >
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {showVideo && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative w-full max-w-4xl">
                        <button
                            className="absolute top-4 right-4 text-white text-2xl"
                            onClick={() => setShowVideo(false)}
                        >
                            &times;
                        </button>
                        <video
                            src="/video/logo.mp4"
                            autoPlay
                            playsInline
                            className="w-full"
                            onClick={() => setShowVideo(false)}
                            onEnded={() => setShowVideo(false)}
                        >
                            {t('video_not_supported')}
                        </video>
                    </div>
                </div>
            )}
        </div>
    );
}