import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, X, Facebook, Instagram, MapPin, Phone, Mail, Check } from 'lucide-react';
import { Star, Award, Trophy, Crown, Diamond } from 'lucide-react';

const TikTokIcon = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
);

const Button = ({ variant, size, onClick, children }) => (
    <motion.button
        className={`relative px-4 py-2 rounded-md overflow-hidden transition-colors duration-300 ${variant === 'default' ? 'bg-orange-500 text-white hover:bg-orange-600' : 'bg-white text-orange-500 border border-orange-500 hover:bg-orange-50'
            } ${size === 'sm' ? 'text-sm' : 'text-base'}`}
        onClick={onClick}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
    >
        <motion.span
            className="absolute inset-0 bg-white"
            initial={{ scale: 0, opacity: 0 }}
            whileHover={{ scale: 1, opacity: 0.2 }}
        />
        {children}
    </motion.button>
);

const Modal = ({ isOpen, onClose, title, icon: Icon, color, children }) => {
    if (!isOpen) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
        >
            <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-lg p-8 max-w-md w-full shadow-2xl"
            >
                <div className={`flex justify-between items-center mb-4 p-4 rounded-t-lg bg-gradient-to-r ${color}`}>
                    <div className="flex items-center">
                        <Icon className="w-8 h-8 mr-2 text-white" />
                        <h2 className="text-2xl font-bold text-white">{title}</h2>
                    </div>
                    <motion.button
                        onClick={onClose}
                        className="text-white hover:text-gray-200"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <X size={24} />
                    </motion.button>
                </div>
                {children}
            </motion.div>
        </motion.div>
    );
};

export default function Publicidad() {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (e, path) => {
        e.preventDefault();
        if (path === '/' || path === '/historia') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (path !== location.pathname) {
            navigate(path);
        }
        setIsMenuOpen(false);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const navItems = [
        { name: t('inicio'), path: "/" },
        { name: t('calendario'), path: "/calendari" },
        { name: t('historia'), path: "/historia" },
        { name: t('presidentes'), path: "/presidents" },
        { name: t('falleras_mayores'), path: "/falleresMajors" },
        { name: t('fallas'), path: "/falles" },
        { name: t('casal'), path: "/casal" },
        { name: t('apuntate'), path: "/apuntat" },
        { name: t('patrocinadores'), path: "/patrocinadores" }
    ];

    const niveles = [
        {
            nombre: t('nivel_diamante'),
            descripcion: t('descripcion_diamante'),
            icon: Diamond,
            color: 'from-blue-500 to-cyan-300',
            hoverColor: 'bg-gradient-to-r from-blue-500 to-cyan-300',
            shadowColor: 'rgba(59, 130, 246, 0.5)',
            beneficios: [
                t('beneficio_diamante_1'),
                t('beneficio_diamante_2'),
                t('beneficio_diamante_3'),
                t('beneficio_diamante_4'),
                t('beneficio_diamante_5'),
                t('beneficio_diamante_6'),
                t('beneficio_diamante_7'),
            ],
            explicacionPrecio: t('explicacion_precio_diamante'),
        },
        {
            nombre: t('nivel_platino'),
            descripcion: t('descripcion_platino'),
            icon: Crown,
            color: 'from-purple-600 to-purple-400',
            hoverColor: 'bg-gradient-to-r from-purple-600 to-purple-400',
            shadowColor: 'rgba(147, 51, 234, 0.5)',
            beneficios: [
                t('beneficio_platino_1'),
                t('beneficio_platino_2'),
                t('beneficio_platino_3'),
                t('beneficio_platino_4'),
                t('beneficio_platino_5'),
                t('beneficio_platino_6'),
            ],
            explicacionPrecio: t('explicacion_precio_platino'),
        },
        {
            nombre: t('nivel_oro'),
            descripcion: t('descripcion_oro'),
            icon: Trophy,
            color: 'from-yellow-500 to-yellow-300',
            hoverColor: 'bg-gradient-to-r from-yellow-500 to-yellow-300',
            shadowColor: 'rgba(234, 179, 8, 0.5)',
            beneficios: [
                t('beneficio_oro_1'),
                t('beneficio_oro_2'),
                t('beneficio_oro_3'),
                t('beneficio_oro_4'),
                t('beneficio_oro_5'),
            ],
            explicacionPrecio: t('explicacion_precio_oro'),
        },
        {
            nombre: t('nivel_plata'),
            descripcion: t('descripcion_plata'),
            icon: Award,
            color: 'from-gray-400 to-gray-300',
            hoverColor: 'bg-gradient-to-r from-gray-400 to-gray-300',
            shadowColor: 'rgba(156, 163, 175, 0.5)',
            beneficios: [
                t('beneficio_plata_1'),
                t('beneficio_plata_2'),
                t('beneficio_plata_3'),
                t('beneficio_plata_4'),
            ],
            explicacionPrecio: t('explicacion_precio_plata'),
        },
        {
            nombre: t('nivel_bronce'),
            descripcion: t('descripcion_bronce'),
            icon: Star,
            color: 'from-amber-700 to-yellow-600',
            hoverColor: 'bg-gradient-to-r from-amber-700 to-yellow-600',
            shadowColor: 'rgba(180, 83, 9, 0.5)',
            beneficios: [
                t('beneficio_bronce_1'),
                t('beneficio_bronce_2'),
                t('beneficio_bronce_3'),
            ],
            explicacionPrecio: t('explicacion_precio_bronce'),
        },
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-red-100">
            <header className="bg-white shadow-lg sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={(e) => handleClick(e, '/')}
                    >
                        <img src="/img/escudo-compress.webp" alt={t('escudo_alt')} className="h-20 w-20 transition-transform duration-300 hover:scale-110" />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {navItems.map((item) => (
                            <Link
                                key={item.name}
                                to={item.path}
                                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                onClick={(e) => handleClick(e, item.path)}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </nav>
                    <div className="hidden md:flex items-center space-x-4">
                        <div className="flex space-x-2">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                CAS
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                VAL
                            </Button>
                        </div>
                        <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Facebook size={24} />
                        </a>
                        <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Instagram size={24} />
                        </a>
                        <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <TikTokIcon size={24} />
                        </a>
                    </div>
                    <motion.button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label={t('toggle_menu')}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </motion.button>
                </div>
                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                            className="md:hidden bg-white py-4 px-4 shadow-inner"
                        >
                            <nav className="flex flex-col space-y-4">
                                {navItems.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.path}
                                        className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                        onClick={(e) => handleClick(e, item.path)}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </nav>
                            <hr className="my-4 border-gray-200" />
                            <div className="flex justify-center space-x-4 mt-4">
                                <Button
                                    variant={i18n.language === 'es' ? "default" : "outline"}
                                    size="sm"
                                    onClick={() => changeLanguage('es')}
                                >
                                    ES
                                </Button>
                                <Button
                                    variant={i18n.language === 'ca' ? "default" : "outline"}
                                    size="sm"
                                    onClick={() => changeLanguage('ca')}
                                >
                                    CA
                                </Button>
                            </div>
                            <div className="flex justify-center space-x-4 mt-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </header>

            <main className="container mx-auto px-4 py-12">
                <motion.h1
                    className="text-4xl md:text-5xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-600"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    {t('publicidad_falla')}
                </motion.h1>

                <LayoutGroup>
                    <motion.div
                        className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-8"
                        initial="hidden"
                        animate="visible"
                        variants={{
                            visible: {
                                transition: {
                                    staggerChildren: 0.05
                                }
                            }
                        }}
                    >
                        {niveles.map((nivel, index) => (
                            <motion.div
                                key={nivel.nombre}
                                layoutId={`card-${index}`}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3 }}
                                className={`bg-white rounded-xl overflow-hidden flex flex-col h-full transform transition-all duration-300 hover:${nivel.hoverColor}`}
                                style={{
                                    contentVisibility: 'auto',
                                    boxShadow: `15px 15px ${nivel.shadowColor}, 10px 10px ${nivel.shadowColor}, 5px 5px ${nivel.shadowColor}`,
                                }}
                            >
                                <motion.div
                                    className={`p-6 bg-gradient-to-r ${nivel.color} text-white relative overflow-hidden flex flex-col items-center justify-center`}
                                    layoutId={`header-${index}`}
                                >
                                    <nivel.icon className="w-12 h-12 mb-4" />
                                    <h2 className="text-2xl font-bold mb-2 text-center">{nivel.nombre}</h2>
                                </motion.div>
                                <motion.div
                                    className="p-6 flex-grow bg-gray-50"
                                    layoutId={`content-${index}`}
                                >
                                    <p className="text-gray-600 mb-4 text-center">{nivel.descripcion}</p>
                                    <ul className="space-y-2">
                                        {nivel.beneficios.map((beneficio, beneficioIndex) => (
                                            <motion.li
                                                key={beneficioIndex}
                                                className="flex items-start"
                                                initial={{ opacity: 0, x: -10 }}
                                                animate={{ opacity: 1, x: 0 }}
                                                transition={{ delay: 0.1 * beneficioIndex }}
                                            >
                                                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                                                <span className="text-sm text-gray-700">{beneficio}</span>
                                            </motion.li>
                                        ))}
                                    </ul>
                                </motion.div>
                                <motion.div
                                    className="px-6 pb-6 mt-auto bg-gray-50"
                                    layoutId={`footer-${index}`}
                                >
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        className={`w-full text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 shadow-md hover:shadow-lg relative overflow-hidden ${nivel.hoverColor}`}
                                        onClick={() => setSelectedLevel(nivel)}
                                        style={{
                                            background: `linear-gradient(to right, ${nivel.color.split(' ').join(', ')})`
                                        }}
                                    >
                                        <motion.span
                                            className="absolute inset-0 bg-white"
                                            initial={{ scale: 0, opacity: 0 }}
                                            whileHover={{ scale: 1, opacity: 0.2 }}
                                        />
                                        {t('precio')}
                                    </motion.button>
                                </motion.div>

                            </motion.div>
                        ))}
                    </motion.div>
                </LayoutGroup>
            </main>

            <AnimatePresence>
                {selectedLevel && (
                    <Modal
                        isOpen={!!selectedLevel}
                        onClose={() => setSelectedLevel(null)}
                        title={selectedLevel.nombre}
                        icon={selectedLevel.icon}
                        color={selectedLevel.color}
                    >
                        <p className="text-gray-700">{selectedLevel.explicacionPrecio}</p>
                    </Modal>
                )}
            </AnimatePresence>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img src="/img/escudo-compress.webp" alt="escudo de la Falla Padre Méndez" className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
                            <p className="text-sm">Falla Padre Méndez © 2024</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('contacto')}</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('siguenos')}</h3>
                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}