import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            es: {
                translation: {
                    // Inici
                    "nombreFalla" : "Falla Padre Méndez y Alrededores",
                    "inicio": "Inicio",
                    "calendario": "Calendario",
                    "historia": "Historia",
                    "presidentes": "Presidentes",
                    "falleras_mayores": "Falleras Mayores",
                    "fallas": "Fallas",
                    "casal": "Casal",
                    "apuntate": "Apúntate",
                    "patrocinadores" : "Patrocínate",
                    "patrocinadoreslogo" : "Patrocinadores",
                    "descubre_mas": "Descubre más",
                    "bienvenidos": "Bienvenidos a la Falla Padre Méndez y Alrededores de Torrent",
                    "fundada_en": "Fundada en 1972 - Más de 50 años de tradición y fiesta",
                    "ultimas_publicaciones": "Últimas Publicaciones",
                    "leer_mas": "Leer más",
                    "anterior": "Anterior",
                    "siguiente": "Siguiente",
                    "contacto": "Contacto",
                    "siguenos": "Síguenos",
                    "dias": "días",
                    "horas": "horas",
                    "minutos": "minutos",
                    "segundos": "segundos",
                    "fallera_mayor": "Fallera Mayor",
                    "presidenta": "Presidenta",
                    "fallera_mayor_infantil": "Fallera Mayor Infantil",
                    "presidente_infantil": "Presidente Infantil",
                    "anos": "Años",
                    "comision": "Comisión",
                    "fallera_mayor_torrent": "Fallera Mayor de Torrent",
                    "fallera_mayor_infantil_torrent": "Fallera Mayor Infantil de Torrent",
                    "corte_honor": "Corte de honor",
                    "monumento_mayor": "Monumento Mayor",
                    "monumento_infantil": "Monumento Infantil",
                    "lema": "Lema",
                    "artista": "Artista",
                    'subvencion_text':"La asociación Cultural Falla Padre Méndez ha sido beneficiaria de la subvención otorgada por el Ayuntamiento de Torrent.",
                    "representantes": "Representantes",
                    "bienvenidos_p1": "Desde 1972, nuestra comisión ha sido un punto de encuentro para vecinos de todas las edades, unidos por la pasión por las Fallas. Hemos compartido momentos inolvidables que forman parte de nuestra historia.",
                    "bienvenidos_p3": "Te invitamos a conocernos, participar en nuestras actividades y descubrir todo lo que ofrecemos. ¡Ven a vivir las Fallas con nosotros!",
                    "monumentos": "Monumentos",
                    "datos_historicos": "Datos históricos",
                    "falles": "Fallas",
                    "days": "Días",
                    "hours": "Horas",
                    "minutes": "Minutos",
                    "seconds": "Segundos",
                    //Calendario
                    "calendar_instruction": "Haga clic en el botón de \"Suscribir\" para agregar los eventos del calendario a su cuenta de Google.",
                    "calendar_instructions_title" : "Sincronitza tots els esdeveniments amb el teu calendari",
                    // Historia
                    "fundacion": "Fundación",
                    "acta_fundacional": "Foto de la acta de la Fundación",
                    "fundacion_p1": "PADRE MÉNDEZ, la antigua calle de pila, que servía de abrevadero para los animales que pasaban por allí, era una de las calles principales de Torrent, la cual creció muy rápido.",
                    "fundacion_p2": "Muchos de los niños nacidos allí crecieron y formaron un gran grupo de amigos.",
                    "fundacion_p3": "En Torrent ya se habían formado 11 fallas cuando uno de esos grupos de amigos, que se reunían cada tarde en el pub 'la rata', decidió formar una falla en la calle de su infancia, y así disfrutar de las fallas de Torrent.",
                    "fundacion_p4": "Muchos ya no están entre nosotros, otros nos siguen desde la barrera y unos pocos siguen a nuestro lado... Por eso queremos destacar que gracias a ellos hoy podemos decir 'yo soy fallero/a de Padre Méndez'.",
                    "fundacion_p5": "Con mucho cariño queremos nombrar a aquellos que fueron los fundadores, los primeros en querer hacer falla y aunque no aparecen en la foto de la firma de ese primer acta y contrato con el artista, estuvieron allí ayudando y aportando todo lo que podían para poder formar nuestra querida comisión.",
                    "fundacion_p5": "A partir de ese momento fueron muchos los que se fueron uniendo a la comisión, llegando a ser unos 70 miembros.",
                    "fundacion_p6": "Nacimos como un grupo de amigos, que pasó a ser una gran familia, para acabar convirtiéndose en la gran falla que somos hoy en día.",
                    "fundadores": "Fundadores",
                    "galeria": "Galería",
                    // Presidentes
                    "ordenar": "Ordenar",
                    "primeros": "Primeros",
                    "ultimos": "Últimos",
                    // Falleras Mayores
                    "filter_by_year": "Filtrar por año",
                    "all_years": "Todos los años",
                    // Monuments
                    "lema_monument_major": "Lema monumento mayor:",
                    "lema_monument_infantil": "Lema monumento infantil:",
                    // Casal
                    "ubicacion_casal": "Ubicación del casal",
                    "como_llegar": "Cómo arribar",
                    "casal_description": "Nuestro querido casal, punto de encuentro para todos los falleros y falleras.",
                    "cresol_quote": "\"Bona nit cresol, que la llum s'apaga.\" (Buenas noches cresol, que la luz se apaga.)",
                    "cresol_description": "El cresol (en castellano candil o crisol), es un recipiente construido en material refractario utilizado para fundir sustancias a temperaturas muy elevadas. También se ha utilizado antiguamente para hacer luz, poniendo dentro del cresol aceite y una mecha que se enciende y produce luz. Lámpara de metal dentro de la cual se pone aceite y una mecha que se enciende. ",
                    // Apuntat
                    "formulario_inscripcion": "Formulario de inscripción",
                    "nombre": "Nombre",
                    "apellidos": "Apellidos",
                    "telefono": "Teléfono",
                    "domicilio": "Domicilio",
                    "fecha_nacimiento": "Fecha de Nacimiento",
                    "loteria": "Lotería",
                    "no": "No",
                    "si": "Sí",
                    "numero_papeletas": "Número de Papeletas",
                    "datos_bancarios": "Datos bancarios",
                    "consentimiento_imagen": "Consentimiento de imagen personal",
                    "enviar": "Enviar",
                    "nombre_obligatorio": "El nombre es obligatorio",
                    "apellidos_obligatorios": "Los apellidos son obligatorios",
                    "dni_invalido": "DNI inválido",
                    "telefono_invalido": "Teléfono inválido",
                    "dni_valido": "Introduce un DNI válido (8 números seguidos de una letra)",
                    "telefono_valido": "Por favor, introduce un número de teléfono válido de 9 dígitos",
                    "nueva_alta": "NUEVA ALTA",
                    "fecha_alta": "Fecha de Alta",
                    "formulario_enviado_exito": "Formulario enviado con éxito",
                    "error_enviar_formulario": "Error al enviar el formulario",
                    "error_generar_pdf": "Error al generar el PDF",
                    "problema_generar_pdf": "Hubo un problema al generar el PDF. Por favor, inténtalo de nuevo.",
                    "elemento_no_encontrado": "El elemento pdfContent no se encontró",
                    "no_se_pudo_generar_pdf": "No se pudo generar el PDF. Por favor, inténtalo de nuevo.",
                    "ficha_alta": "Ficha de alta",
                    "descargar_documento_instrucciones": "O puedes descargar el siguiente documento, complétalo con tus datos y envíalo al siguiente correo:",
                    "descargar_pdf": "Descárgate el PDF",
                    "ficha_alta_alt": "Ficha de alta",
                    "publicidad_falla" : "Publicítate en la falla",
                    "nivel_diamante": "Nivel Diamante",
                    "nivel_platino": "Nivel Platino",
                    "nivel_oro": "Nivel Oro",
                    "nivel_plata": "Nivel Plata",
                    "nivel_bronce": "Nivel Bronce",
                    "precio" : "Consulta el precio",
                    "explicacion_precio_diamante" : "El precio mensual para el nivel diamante es de 125€ al mes. Para más información envíanos un correo a: secretariofallapadremendez@gmail.com",

                    

                }
            },
            ca: {
                translation: {
                    // Inici
                    "nombreFalla" : "Falla Pare Méndez i Voltants",
                    "inicio": "Inici",
                    "calendario": "Calendari",
                    "historia": "Història",
                    "presidentes": "Presidents",
                    "falleras_mayores": "Falleres Majors",
                    "fallas": "Falles",
                    "casal": "Casal",
                    "apuntate": "Apunta't",
                    "patrocinadores" : "Patrocina't",
                    "patrocinadoreslogo" : "Patrocinadors",
                    "descubre_mas": "Descobreix més",
                    "bienvenidos": "Benvinguts a la Falla Pare Méndez i Voltants de Torrent",
                    "fundada_en": "Fundada al 1972 - Més de 50 anys de tradició i festa",
                    "ultimas_publicaciones": "Últimes Publicacions",
                    "leer_mas": "Llegir més",
                    "anterior": "Anterior",
                    "siguiente": "Següent",
                    "contacto": "Contacte",
                    "siguenos": "Segueix-nos",
                    "dias": "dies",
                    "horas": "hores",
                    "minutos": "minuts",
                    "segundos": "segons",
                    "fallera_mayor": "Fallera Major",
                    "presidenta": "Presidenta",
                    "fallera_mayor_infantil": "Fallera Major Infantil",
                    "presidente_infantil": "President Infantil",
                    "anos": "Anys",
                    "comision": "Comissió",
                    "fallera_mayor_torrent": "Fallera Major de Torrent",
                    "fallera_mayor_infantil_torrent": "Fallera Major Infantil de Torrent",
                    "corte_honor": "Cort d'honor",
                    "monumento_mayor": "Monument Major",
                    "monumento_infantil": "Monument Infantil",
                    "lema": "Lema",
                    "artista": "Artista",
                    'subvencion_text': "L'associació Cultural Falla Pare Méndez ha sigut beneficiària de la subvenció atorgada per l'Ajuntament de Torrent.",
                    "representantes": "Representants",
                    "bienvenidos_p1": "Des de 1972, la nostra comissió ha sigut un punt de trobada per a veïns de totes les edats, units per la passió per les Falles. Hem compartit moments inoblidables que formen part de la nostra història.",
                    "bienvenidos_p3": "T'invitem a conéixer-nos, participar en les nostres activitats i descobrir tot el que oferim. Vine a viure les Falles amb nosaltres!",
                    "monumentos": "Monuments",
                    "datos_historicos": "Dades històriques",
                    "falles": "Falles",
                    "days": "Dies",
                    "hours": "Hores",
                    "minutes": "Minuts",
                    "seconds": "Segons",
                    // Calendario
                    "calendar_instruction": "Fes click al botó de \"Suscribir\" per agregar els events del calendari al teu Google Calendar.",
                    "calendar_instructions_title" : "Sincronitza tots els esdeveniments amb el teu calendari",
                    // Historia
                    "fundacion": "Fundació",
                    "acta_fundacional": "Foto acta de la Fundació",
                    "fundacion_p1": "PADRE MÉNDEZ, l'antic carrer de pila, que servia d'abeurador per als animals que passaven per allí, era un dels carrers principals de Torrent, la qual va créixer molt de pressa.",
                    "fundacion_p2": "Molts dels xiquets nascuts allí van créixer i van anar forman un gran grup d'amics.",
                    "fundacion_p3": "A Torrent ja s'havien format 11 falles quan un d'aqueixos grups d'amics es reunien cada vesprada en el pub \"la rata\", que van decidir formar una falla al carrer de la seva infància, i així gaudir de les falles de Torrent.",
                    "fundacion_p4": "Molts no estan entre nosaltres, uns altres ens segixen ja des de la barrera i altres pocs segueixen al nostre costat... Per això volem resaltar que gràcies a ells hui podem dir \"jo soc faller/a de Padre Méndez\".",
                    "fundacion_p5": "Amb molt d'afecte volem nomenar aquells que van ser els fundadors, els primers en voler fer falla i encara que no apareixen en la foto de signatura d'aqueixa primera acta i contracte amb l'artista, estaven allí ajudant i aportant tot el que es podia per a poder formar la nostra benvolguda comissió.",
                    "fundacion_p5": "A partir d'aquest moment van ser molts els que es van anar unint a la comissió arribant a ser uns 70 membres.",
                    "fundacion_p6": "Vam nàixer com un grup d'amics, que va passar a ser una gran família, per acabar convertint-se en la gran falla de hui en dia.",
                    "fundadores": "Fundadors",
                    "galeria": "Galeria",
                    // Presidentes
                    "ordenar": "Ordenar",
                    "primeros": "Primers",
                    "ultimos": "Últims",
                    // Falleras Mayores
                    "filter_by_year": "Filtrar per any",
                    "all_years": "Tots els anys",
                    // Monuments
                    "lema_monument_major": "Lema monument major:",
                    "lema_monument_infantil": "Lema monument infantil:",
                    // Casal
                    "ubicacion_casal": "Ubicació del casal",
                    "como_llegar": "Com arribar",
                    "casal_description": "El nostre estimat casal, punt de trobada per a tots els fallers i falleres.",
                    "cresol_quote": "\"Bona nit cresol, que la llum s'apaga.\"",
                    "cresol_description": "El cresol (en castellà candil o crisol), és un recipient construït en material refractari utilisat per a fondre substàncies a temperatures molt elevades. També ha segut utilisat antigament per a fer llum ficant dins del cresol oli i un fil que es pren i fa llum. Llàntia de metal dins de la qual se posa oli i fil que es pren.",
                    "formulario_inscripcion": "Formulari d'inscripció",
                    "nombre": "Nom",
                    "apellidos": "Cognoms",
                    "telefono": "Telèfon",
                    "domicilio": "Domicili",
                    "fecha_nacimiento": "Data de Naixement",
                    "loteria": "Loteria",
                    "no": "No",
                    "si": "Sí",
                    "numero_papeletas": "Nombre de Paperetes",
                    "datos_bancarios": "Dades bancàries",
                    "consentimiento_imagen": "Consentiment d'imatge personal",
                    "enviar": "Enviar",
                    "nombre_obligatorio": "El nom és obligatori",
                    "apellidos_obligatorios": "Els cognoms són obligatoris",
                    "dni_invalido": "DNI invàlid",
                    "telefono_invalido": "Telèfon invàlid",
                    "dni_valido": "Introdueix un DNI vàlid (8 números seguits d'una lletra)",
                    "telefono_valido": "Per favor, introdueix un número de telèfon vàlid de 9 dígits",
                    "nueva_alta": "NOVA ALTA",
                    "fecha_alta": "Data d'Alta",
                    "formulario_enviado_exito": "Formulari enviat amb èxit",
                    "error_enviar_formulario": "Error en enviar el formulari",
                    "error_generar_pdf": "Error en generar el PDF",
                    "problema_generar_pdf": "Hi ha hagut un problema en generar el PDF. Per favor, torna-ho a intentar.",
                    "elemento_no_encontrado": "L'element pdfContent no s'ha trobat",
                    "no_se_pudo_generar_pdf": "No s'ha pogut generar el PDF. Per favor, torna-ho a intentar.",
                    "ficha_alta": "Fitxa d'alta",
                    "descargar_documento_instrucciones": "O pots descarregar el següent document, completa'l amb les teves dades i envia'l al següent correu:",
                    "descargar_pdf": "Descarrega't el PDF",
                    "ficha_alta_alt": "Fitxa d'alta",
                    "contacto": "Contacte",
                    "siguenos": "Segueix-nos",
                    "publicidad_falla" : "Publicita't a la falla",
                    "nivel_diamante": "Nivell Diamant",
                    "nivel_platino": "Nivell Platí",
                    "nivel_oro": "Nivell Or",
                    "nivel_plata": "Nivell Plata",
                    "nivel_bronce": "Nivell Bronze",
                    "precio" : "Consulta el preu",

                }
            }
        },
        fallbackLng: "es",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;