import React from 'react';
import { Download } from 'lucide-react';
import styles from './ButtonDownload.module.css';

const PDFDownloadButton = ({ onClick, text }) => {
    return (
        <div className={styles.btn3Wrapper}>
            <button onClick={onClick} className={styles.btn3}>
                <span className={styles.texto}>{text}</span>
                <Download className={styles.mysvg} size={24} />
            </button>
        </div>
    );
};

export default PDFDownloadButton;